// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/fabio/Documents/projects/training-website-2019/training-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-corso-angular-core-concepts-js": () => import("/Users/fabio/Documents/projects/training-website-2019/training-website/src/pages/corso-angular-core-concepts.js" /* webpackChunkName: "component---src-pages-corso-angular-core-concepts-js" */),
  "component---src-pages-corso-angular-forms-js": () => import("/Users/fabio/Documents/projects/training-website-2019/training-website/src/pages/corso-angular-forms.js" /* webpackChunkName: "component---src-pages-corso-angular-forms-js" */),
  "component---src-pages-corso-angular-ngrx-js": () => import("/Users/fabio/Documents/projects/training-website-2019/training-website/src/pages/corso-angular-ngrx.js" /* webpackChunkName: "component---src-pages-corso-angular-ngrx-js" */),
  "component---src-pages-forms-richiedi-preventivo-corso-js": () => import("/Users/fabio/Documents/projects/training-website-2019/training-website/src/pages/forms/richiedi-preventivo-corso.js" /* webpackChunkName: "component---src-pages-forms-richiedi-preventivo-corso-js" */),
  "component---src-pages-index-js": () => import("/Users/fabio/Documents/projects/training-website-2019/training-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-3-jsx": () => import("/Users/fabio/Documents/projects/training-website-2019/training-website/src/pages/page3.jsx" /* webpackChunkName: "component---src-pages-page-3-jsx" */)
}

